export const PaymentFlowType = {
  NOT_SET: 0,
  PRIMARY_TRADE: 1,
  SECONDARY_TRADE_BUY_ORDER: 2,
  UNDERLYING_INVESTMENT: 3,
  SALES_FEE: 4,
  CASH_TRANSFER: 5,
  SECONDARY_TRADE_SELL_ORDER: 6,
  QUARTERLY_FEES: 7,
  UNDERLYING_INVESTMENT_NO_PAYMENT: 8,
};

export const PaymentInstructionGeneratedDocType = {
  PAYING_AGENT_ISP: 0,
  PRODUCT_SPONSOR_G2_CASH_TRANSFER: 1,
  PRODUCT_SPONSOR_G2_QUARTERLY_FEE: 2,
  PRODUCT_SPONSOR_G2_SALES_FEE: 3,
  PAYING_AGENT_InCore: 4,
  PAYING_AGENT_InCore_NoIntermediary: 5
};

export const PaymentExecution = {
  1: "Immediate",
  2: "Forward start",
};

export const PaymentFlowConceptType = {
  NOT_SET: 0,
  AMENDMENT: 1,
  SUBSCRIPTION_FORM: 2,
  OTHER: 3,
};

export const AccountType = {
  NOT_SET: 0,
  PAYING_AGENT: 1 << 0,
  CUSTODIAN: 1 << 1,
  UNDERLYING_INVESTMENT: 1 << 2,
  BANK_CLIENT: 1 << 3,
  CLIENT_ACCOUNT: 1 << 4,
  PARTY_CLIENT_ACCOUNT: 1 << 5,
  ISSUER_PAYING_AGENT: 1 << 6,
  CRYPTO_EXCHANGE: 1 << 7,
  PRODUCT: function () {
    return (
      AccountType.PAYING_AGENT |
      AccountType.CUSTODIAN |
      AccountType.UNDERLYING_INVESTMENT |
      AccountType.BANK_CLIENT
    );
  },
  CLIENT: function () {
    return AccountType.CLIENT_ACCOUNT;
  },
  ISSUER: function () {
    return AccountType.ISSUER_PAYING_AGENT;
  },
  PARTY: function () {
    return AccountType.PARTY_CLIENT_ACCOUNT;
  },
  CRYPTO: function () {
    return AccountType.CRYPTO_EXCHANGE;
  },
};

export const BatchStatus = {
  NotStarted: 0,
  Draft: 1, // this doesn't exist on the backend
  Pending: 5,
  Retrying: 10,
  Cancelled: 15,
  CompletedWithFailure: 18,
  Completed: 20,
  InteractionError: -5,
  Failed: -10,
  CancellationError: -15,
};
